import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';
import { ProcessCSV } from 'src/app/core/models/ProcessCSV';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { ToastService } from 'src/app/services/toast.service';
import { headerWithValue } from 'src/app/core/models/contact';

@Component({
  selector: 'app-process-csv',
  templateUrl: './process-csv.component.html',
  styleUrls: ['./process-csv.component.css'],
})
export class ProcessCSVComponent implements OnInit {
  @ViewChild('fileInput') fileInputVariable!: ElementRef;
  @ViewChild('tagsInput') tagInput!: ElementRef<HTMLInputElement>;
  @Output() selectElementEvent = new EventEmitter();

  @Output() closeAction = new EventEmitter();

  save = true;
  msgErr: string;
  installmentCheck: any[] = [];
  fixedOrDinamic: any[] = [];
  currentSelectedElement: any;
  msgLoading = 'La operacion esta siendo procesada puede tardar varios minutos';
  title = 'SMaaS_Mapping-spa';
  csvContent: string = '';
  headers: headerWithValue[] = [];
  selectedFile: any;
  fileReader: FileReader;
  fileR: FileReader;
  fileName: string = '';
  fileType: string = '';
  fileSize: string = '';
  fileN: string = '';
  fileT: string = '';
  fileS: string = '';
  uploadProgress = 0;
  initUploadCSV = false;
  properties: string[] = [' '];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  iconTrash = faTrash;
  values: string[] = [];
  valuesPerLines: any[] = [];
  newFile: any;
  file: any;
  newData: any;
  newAccountStatus: any;
  contract: ProcessCSV[] = [];
  service: number = 0;
  homePage = '';
  iconSee = faEye;
  iconRemove = faTrash;
  confirmBoxTitles: { title: string; subtitle: string };
  header: string[] = [
    '',
    '#',
    'Propiedad',
    'Inquilino',
    'Propietario',
    'Honorarios',
    'Cuotas',
    'Comienzo',
    'vencimiento',
    'Garante',
    'Aumento por Mora',
    'Cuotas pagas',
    'Comercial',
    'Estado de Cuenta',
  ];
  expirationIncrease: number[] = [];
  installmentPay: boolean;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
  ) {
    this.fileReader = new FileReader();
  }

  ngOnInit(): void {
    this.expirationIncrease = [
      0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
      0.1, 0.1, 0.1, 0.1, 0.1,
    ];
  }

  private readUploadedFileAsText() {
    return new Promise((resolve, reject) => {
      this.fileReader.onerror = () => {
        this.fileReader.abort();
        reject(new DOMException('Problem parsin input file.'));
      };

      this.fileReader.onload = () => {
        resolve(this.fileReader.result);
      };
    });
  }

  async onUpload() {
    if (this.selectedFile != null) {
      await this.readUploadedFileAsText().then((x: any) =>
        console.log('upload ended', x),
      );
    }
  }

  clearCSV() {
    this.selectedFile = null;
    this.fileName = '';
    this.fileSize = '';
    this.fileType = '';
    this.fileReader = new FileReader();
    this.csvContent = '';
    this.fileInputVariable.nativeElement.value = '';
    this.values = [];
    this.valuesPerLines = [];
    this.newData = undefined;
  }

  public async onFileSelect(input: any) {
    if (input != null) {
      const files = input.files;
      if (files && files.length) {
        this.fileName = files[0].name;
        this.fileType = files[0].type;
        this.fileSize = files[0].size;
        this.selectedFile = input.files[0];
        const fd = new FormData();
        fd.append('fileForAdd', this.selectedFile, this.selectedFile.name);
        this.apiService.fileUpload(fd).subscribe(
          (data) => {
            (this.newData = data),
              this.toastService.showToast(
                'success',
                'El archivo fue cargado con exito',
              );
          },
          (error) => {
            this.toastService.showToast(
              'error',
              'El archivo no pudo ser cargado, verifique que el formato sea el correcto',
            );
            this.clearCSV();
          },
        );
      }

      this.fileReader.readAsText(this.selectedFile, 'UTF-8');
    }
  }

  removeContract(index: number) {
    this.newData.splice(index, 1);
  }

  public async statusAccountFileSelect(input: any, index: number) {
    if (input != null) {
      this.contract = this.newData[index];
      const files = input.files;
      if (files && files.length) {
        this.fileN = files[0].name;
        this.fileT = files[0].type;
        this.fileS = files[0].size;
        this.selectedFile = input.files[0];
        const fd = new FormData();
        fd.append('FileForAdd', this.selectedFile, this.selectedFile.name);
        fd.append('Contract', JSON.stringify(this.contract));
        this.apiService.fileUploadContractState(fd).subscribe(
          (data) => {
            this.newData[index].state = data;
            this.toastService.showToast(
              'success',
              'Estado de cuenta cargado exisotasemente',
            );
          },
          (error) => {
            this.toastService.showToast(
              'error',
              'El archivo no pudo ser cargado, verifique que sea un estado de cuenta',
            );
          },
        );
      }
    }
    this.fileR.readAsText(this.selectedFile, 'UTF-8');
  }

  clearStatusAccount(index: number) {
    this.newData[index].state = null;
  }

  InstallmentQuantity(index: number) {
    let count = 0;
    for (let i = 0; i < this.newData[index].state.installments.length; i++) {
      count += 1;
    }
    return count;
  }

  InstallmentRequirementQuantity(index: number) {
    let iReqs = [];
    this.newData[index].state.installments.forEach((installment) => {
      installment.installmentRequirements.forEach((instRequirement) => {
        if (!iReqs.some((e) => e.details === instRequirement.details)) {
          iReqs.push(instRequirement);
        }
      });
    });
    return iReqs.length;
  }

  returnSelectElement(row, isChecked, index) {
    var returnElement = {
      data: this.fixedOrDinamic[index],
      status: isChecked.checked,
      index: index,
    };
    this.currentSelectedElement = returnElement;
    this.selectElementEvent.emit(returnElement);
  }

  saveContract(contracts: ProcessCSV[]) {
    this.save = false;
    for (let index = 0; index < contracts.length; index++) {
      contracts[index].installmentPay = this.installmentCheck[index]
        ? true
        : false;
      contracts[index].installmentTypeId = this.fixedOrDinamic[index]
        ? true
        : false;
      contracts[index].expirationIncrease = this.expirationIncrease[index];
    }
    this.apiService.saveContract(contracts).subscribe(
      (data) => {
        this.save = true;
        this.toastService.showToast('success', 'Csv cargado exitosamente');
        this.clearCSV();
      },
      (error) => {
        this.save = true;
        this.toastService.showToast(
          'error',
          'Hubo un problema cargando el archivo',
        );
      },
    );
  }
}
